/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'stylesheets/application'
import 'jquery'
import Turbolinks from 'turbolinks'
import Rails from 'rails-ujs'
import 'bootstrap/dist/js/bootstrap'
import "@nathanvda/cocoon"
import Jsona from 'jsona'
import 'jscroll'
// ALICE独自共通部品
import checkboxCommon from 'javascripts/_checkbox.js'

Turbolinks.start();
Rails.start();

window.Jsona = Jsona;
window.checkboxCommon = checkboxCommon;

// @note 任意のpushStateとturbolinksを連携させる。
//   history.pushStateを使用する場合はstateにturbolinks: trueをつけてください
window.onpopstate = function(e) {
  if(e.state && e.state.turbolinks) {
    Turbolinks.visit(document.URL);
  }
};

document.addEventListener("turbolinks:load", function(){
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-bs-toggle="popover"]').popover();
}, false);

document.addEventListener("DOMContentLoaded", function() {
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-bs-toggle="popover"]').popover();
}, false);

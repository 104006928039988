export { checkboxCommon as default };

const checkboxCommon = {
  allChecked: function(allCheckboxElementId, lineCheckboxElementClassName) {
    "use strict";

    let allCheckboxElem = document.getElementById(allCheckboxElementId);

    let lineCheckboxes = Array.from(document.getElementsByClassName(lineCheckboxElementClassName));
    lineCheckboxes.forEach(function(elem) {
      if (elem.disabled) {
        return;
      }
      elem.checked = allCheckboxElem.checked;
    });
  },
  lineChecked: function(allCheckboxElementId, lineCheckboxElementClassName) {
    "use strict";

    let checkedCount = 0;
    let allCheckboxElem = document.getElementById(allCheckboxElementId);
    let lineCheckboxes = Array.from(document.getElementsByClassName(lineCheckboxElementClassName));
    lineCheckboxes.forEach(function(checkbox) {
      if (!checkbox.disabled && checkbox.checked == false) {
        allCheckboxElem.checked = false;
      } else {
        checkedCount += 1;
        if (checkedCount == lineCheckboxes.length) {
          allCheckboxElem.checked = true;
        }
      }
    });
  }
};
